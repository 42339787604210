const eventType = 'refresh_select_menu'

interface RefreshSelectMenuEvent extends Event {
  readonly selectMenu: HTMLSelectElement
}

class RefreshSelectMenuEventImpl extends Event implements RefreshSelectMenuEvent {
  constructor(readonly selectMenu: HTMLSelectElement) {
    super(eventType);
  }
}

export const registerSelectMenuRefreshHandler = (block: (selectMenu: HTMLSelectElement) => void) => {
  document.addEventListener(eventType, e => {
    if ('selectMenu' in e) {
      block((e as RefreshSelectMenuEvent).selectMenu)
    }
  })
}

export const refreshSelectMenu = (selectMenu: HTMLSelectElement) => {
  document.dispatchEvent(new RefreshSelectMenuEventImpl(selectMenu))
}
